import * as React from "react";
import { Link, graphql } from "gatsby";
import BlogReachView from "../../../views/BlogReachView";

// import Bio from "../components/bio"
// import Layout from "../components/layout"
// import Seo from "../components/seo"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <BlogReachView posts={posts} />
    // <ol style={{ listStyle: `none` }}>
    //   {posts.map((post) => {
    //     const title = post.frontmatter.title || post.fields.slug;
    //
    //     return (
    //       <li>
    //         <article
    //           className="post-list-item"
    //           itemScope
    //           itemType="http://schema.org/Article"
    //         >
    //           <header>
    //             <h2>
    //               <Link to={post.fields.slug} itemProp="url">
    //                 <span itemProp="headline">{title}</span>
    //               </Link>
    //             </h2>
    //             <small>{post.frontmatter.date}</small>
    //           </header>
    //           <section>
    //             <p
    //               dangerouslySetInnerHTML={{
    //                 __html: post.frontmatter.description || post.excerpt,
    //               }}
    //               itemProp="description"
    //             />
    //           </section>
    //         </article>
    //       </li>
    //     );
    //   })}
    // </ol>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "/(blog/ja/)/" } } }
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          title
          description
          featuredImage {
            publicURL
          }
        }
      }
    }
  }
`;
